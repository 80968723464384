import { createI18n } from 'vue-i18n';

const messages = {
    en: {
        player: {
            title: "ARAB CAT SONG"
        },
        navbar: {
            home: "Home",
            about: "About",
            friends: "Friends",
            tool: "PFP Tool",
            swap: "Swap"
        },
        main: {
            arab: "ARAB",
            cat: "CAT"
        },
        about: {
            title: "Who is Arab Cat?",
            text1: "Arab Cat is a multi billionaire sheikh living in the Burj Khalifa",
            text2: "Arab Cat's wealth came from trading crypto since the launch of Bitcoin",
            text3: "He then got into the meme coin industry where he launched his own meme coin in order to onboard new traders and educate them about the crypto industry",
            text4: "Arab Cat decided to name his memecoin  “$ARAB” and launched it on the Solana Blockchain",
            text5: "Now Arab Cat plans to diversify his investments and wants to start his own oil company",
        },
        friends: {
            title: "Arab Cat's Friends",
            open: "Open"
        },
        tool: {
            title: "Habibify Your PFP",
            select: "Select image",
            save: "Save image",
            delete: "Delete"
        },
        swap: {
            title: "BUY $ARAB NOW",
            ticker: "Ticker:",
            ca: "CA:",
            lp: "LP:",
            locked: "Locked",
            supply: "Total supply:"
        }
    },
    ar: {
        player: {
            title: "أغنية القط العربي"
        },
        navbar: {
            home: "الرئيسية",
            about: "نبذة عن المشروع",
            friends: "شركائنا",
            tool: "اداة تعديل الصورة",
            swap: "شراء"
        },
        main: {
            arab: "العربي",
            cat: "القط"
        },
        about: {
            title: "من هو القط العربي؟",
            text1: "القط العربي هو شيخ ملياردير يعيش في برج خليفة",
            text2: "جاءت ثروة القط العربي من تداول العملات المشفرة منذ إطلاق البيتكوين",
            text3: "ثم استثمر في العملات الميمية حيث أطلق عملة الميم الخاصة به من أجل ضم المتداولين الجدد وتثقيفهم حول هذا النوع من العملات.",
            text4: "قرر القط العربي تسمية عملة الميم التي اطلقها  باسم \"Arab$\" وأطلقها على شبكة سلولانا (solana)",
            text5: "والآن يخطط القط العربي على تنويع استثماراته ويريد إنشاء شركة نفط خاصة به",
        },
        friends: {
            title: "شركائنا",
            open: "فتح"
        },
        tool: {
            title: "اداة تعديل الصورة",
            select: "اختر صورة",
            save: "احفظ الصورة",
            delete: "مسح"
        },
        swap: {
            title: "اشتري عملة القط العربي الان",
            ticker: ":المؤشر",
            ca: ":عنوان",
            lp: ":حوض الإطلاق",
            locked: "مقفل",
            supply: ":إجمالي العرض"
        }
    },
};

const i18n = createI18n({
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    messages,
});

export default i18n;
