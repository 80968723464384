<template>
  <div class="app">
    <Navbar/>
    <Main/>
    <About/>
    <Media/>
    <Friends/>
    <Habibifier/>
    <Swap/>
    <Footer/>
  </div>
</template>

<script setup lang="ts">
import './styles.css';
import Main from "@/blocks/Main.vue";
import About from "@/blocks/About.vue";
import Friends from "@/blocks/Friends.vue";
import Swap from "@/blocks/Swap.vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/blocks/Footer.vue";
import Habibifier from "@/blocks/Habibifier.vue";
import {onMounted} from "vue";
import Media from "@/blocks/Media.vue";

onMounted(() => {
  let threshold;
  window.innerWidth > 450 ? threshold = 0.35 : threshold = 0.15;

  let observer = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        (entry.target as HTMLElement).style.opacity = '1';
        entry.target.querySelector('.terminal__prompt--typing')?.classList.add('start-typing');
        observer.unobserve(entry.target);
      }
    });
  }, {
    threshold: threshold
  });

  const targetElements = document.querySelectorAll('.about, .swap, .friends, .media');

  targetElements.forEach(targetElement => {
    observer.observe(targetElement);
  });

})
</script>

<style lang="sass">
*
  margin: 0
  padding: 0
  box-sizing: border-box
.app
  display: flex
  flex-direction: column
  background-color: #0c0c0c
  background-image: url("@/assets/bg1.png")
  background-repeat: revert
  background-attachment: fixed
</style>
