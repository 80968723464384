import { defineStore } from 'pinia';
import { useI18n } from 'vue-i18n';
import {ref} from "vue";
import { nextTick } from 'vue';


export const useMainStore = defineStore('mainStore', () => {
  const { locale } = useI18n({
    useScope: 'global',
  });
  const activeLang = ref('en');

  function setLocale(lang: string) {
    locale.value = lang;
    activeLang.value = lang;

    nextTick(() => {
      window.Jupiter.init({
        displayMode: "integrated",
        integratedTargetId: "integrated-terminal",
        endpoint: "https://solana-mainnet.g.alchemy.com/v2/dTg-L2h6F9t_e1XPm97EoOLwYDa1Uz1a",
        strictTokenList: false,
        formProps: {
          fixedOutputMint: true,
          initialOutputMint: "BFpchrNVhyTRzMNAg9QkiZfRN2vqRBwcYoTX8qgkbDvm",
        },
      });

      let threshold;
      window.innerWidth > 450 ? threshold = 0.35 : threshold = 0.15;

      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            (entry.target as HTMLElement).style.opacity = '1';
            entry.target.querySelector('.terminal__prompt--typing')?.classList.add('start-typing');
            observer.unobserve(entry.target);
          }
        });
      }, {
        threshold: threshold
      });

      const targetElements = document.querySelectorAll('.about, .swap, .friends');

      targetElements.forEach(targetElement => {
        observer.observe(targetElement);
      });
    })

  }

  return {
    activeLang,
    setLocale,
  };
});
