<template>
  <div class="main" id="main">
    <div class="content">
      <div class="logo" v-if="mainStore.activeLang === 'en'">
        <h1 class="logo_arab">{{ t('main.arab') }}</h1>
        <img src="@/assets/cat.png" alt="cat" class="logo_cat-pic">
        <h1 class="logo_cat">{{ t('main.cat') }}</h1>
      </div>
      <div class="logo" v-else>
        <h1 class="logo_arab" :class="{arabic: mainStore.activeLang === 'ar'}">{{ t('main.arab') }}</h1>
        <img class="logo_cat-pic" :class="{arabic: mainStore.activeLang === 'ar'}" src="@/assets/cat.png" alt="cat">
        <h1 class="logo_cat" :class="{arabic: mainStore.activeLang === 'ar'}">{{ t('main.cat') }}</h1>
      </div>
      <div class="socials">
        <a href="https://www.coingecko.com/en/coins/arab-cat" target="_blank">
          <p>Coingecko</p>
        </a>
        <a href="https://dexscreener.com/solana/EfQGhHPSc9mU5gooddL3cjxsGPcEJggZuQc9L87vif6t" target="_blank">
          <p>DEX Screener</p>
        </a>
        <a href="https://twitter.com/arabcatcoin" target="_blank">
          <p>Twitter</p>
        </a>
        <a href="https://t.me/arabcatcoin" target="_blank">
          <p>Telegram</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import {useMainStore} from "@/store/mainStore";

const mainStore = useMainStore()
const { t } = useI18n();
</script>

<style lang="sass" scoped>
.main
  position: relative
  display: flex
  align-items: center
  justify-content: center
.content
  width: 100%
  max-width: 2000px
  height: 100vh
  z-index: 2
  flex-direction: column
  display: flex
  align-items: center
  justify-content: center
.logo
  display: flex
  align-items: flex-end
  justify-content: center
  margin-bottom: 5vh
  & h1
    font-size: 22.5vw
    background-image: url('@/assets/mandala.png')
    background-size: contain
    background-clip: text
    -webkit-background-clip: text
    color: transparent
    &.arabic
      font-size: 19vw
  &_arab
    z-index: 2
    color: transparent
    animation: slideFromLeft 1s ease forwards
    margin-right: -4vw
    &.arabic
      margin-right: -3vw
      z-index: 1
  &_cat-pic
    opacity: 0
    z-index: 1
    width: 20vw
    animation: fadeIn 1s ease-in-out forwards 0.5s
    padding-bottom: 1vw
    margin-right: -8vw
    &.arabic
      margin-right: -7vw
      width: 19vw
      margin-bottom: 2vw
  &_cat
    animation: slideFromRight 1s ease forwards
    &.arabic
      z-index: 2
.socials
  display: flex
  justify-content: center
  gap: 70px
  font-size: 12pt
  color: #FEFAE0FF
  & p
    cursor: pointer
    transition: 0.4s
    position: relative
    display: inline-block
    &::before
      content: ""
      position: absolute
      top: 100%
      left: 0
      width: 0
      height: 1px
      background-color: #FEFAE0FF
      transition: width 0.2s ease
    &:hover::before
      width: 100%
@media (max-width: 850px)
  .socials
    flex-direction: column
    align-items: center
    gap: 50px
    font-size: 10pt
@media (max-width: 650px)
  .socials
    flex-direction: column
    align-items: center
    gap: 50px
    font-size: 10pt
@keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 100%
@keyframes slideFromLeft
  from
    transform: translateX(-200px)
    opacity: 0
  to
    transform: translateX(0)
    opacity: 1
@keyframes slideFromRight
  from
    transform: translateX(200px)
    opacity: 0
  to
    transform: translateX(0)
    opacity: 1
</style>