import { createApp } from 'vue'
import App from './App.vue'
import {createPinia} from "pinia";
import VueKonva from 'vue-konva'
import i18n from './i18n'

createApp(App)
  .use(createPinia())
  .use(VueKonva)
  .use(i18n)
  .mount('#app')
